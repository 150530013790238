exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-howtoorder-js": () => import("./../../../src/pages/howtoorder.js" /* webpackChunkName: "component---src-pages-howtoorder-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-thanks-js": () => import("./../../../src/pages/thanks.js" /* webpackChunkName: "component---src-pages-thanks-js" */),
  "component---src-templates-book-post-js": () => import("./../../../src/templates/bookPost.js" /* webpackChunkName: "component---src-templates-book-post-js" */),
  "component---src-templates-books-archive-js": () => import("./../../../src/templates/booksArchive.js" /* webpackChunkName: "component---src-templates-books-archive-js" */),
  "component---src-templates-books-category-js": () => import("./../../../src/templates/booksCategory.js" /* webpackChunkName: "component---src-templates-books-category-js" */),
  "component---src-templates-news-archive-js": () => import("./../../../src/templates/newsArchive.js" /* webpackChunkName: "component---src-templates-news-archive-js" */),
  "component---src-templates-news-category-js": () => import("./../../../src/templates/newsCategory.js" /* webpackChunkName: "component---src-templates-news-category-js" */),
  "component---src-templates-news-post-js": () => import("./../../../src/templates/newsPost.js" /* webpackChunkName: "component---src-templates-news-post-js" */)
}

